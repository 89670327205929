<template>
    <section class="flex items-center relative px-6 md:px-14 py-4 justify-between z-50 border-b border-white h-[82px]">
        <div class="flex items-center justify-between w-full">
            <InertiaLink :href="route('home')">
                <ApplicationLogoBlack class="lg:h-12 h-[38px]" />
            </InertiaLink>

            <div class="flex items-center gap-2 xl:gap-8 font-semibold text-[13px]">
                <div class="hidden lg:flex items-center gap-2 xl:gap-8">
                    <a
                        v-for="menu in primaryLinks"
                        :key="menu.href"
                        :href="menu.href"
                        class="px-2 py-[14px] uppercase hover:text-primary">
                        {{ menu.label }}
                    </a>
                </div>
                <div class="flex gap-2">
                    <InertiaLink
                        v-for="button in primaryButtons"
                        :key="button.href"
                        :href="route(button.href)"
                        :class="[
                            'hidden md:flex gap-x-2 text-[13px] text-center h-12  font-semibold uppercase rounded-full px-6 py-[14px] ',
                            button.hasBackground
                                ? 'bg-base-content text-white'
                                : ' text-base-content border border-base-content',
                        ]">
                        <span>{{ button.label }} </span><ArrowUpRightIcon class="size-4" />
                    </InertiaLink>
                </div>

                <Bars2Icon class="size-9 cursor-pointer" @click="isMenuOpened = !isMenuOpened" />
            </div>
        </div>
        <section
            v-if="isMenuOpened"
            class="flex flex-col fixed right-0 top-0 w-full md:max-w-[694px] bg-white h-full z-40 overflow-y-auto md:overflow-auto">
            <div class="h-[82px]">
                <div class="flex items-center justify-end px-6 py-4">
                    <XMarkIcon
                        class="w-[34px] h-[34px] z-50 mt-2 ml-[-1.8rem] md:mr-2 cursor-pointer"
                        @click="isMenuOpened = false" />
                </div>
            </div>

            <div class="flex flex-col px-6 md:px-20 pb-4">
                <template v-for="menu in sidebarMenuLinks" :key="menu.href">
                    <template v-if="!menu.external">
                        <InertiaLink
                            v-if="menu.isRoute"
                            :href="route(menu.href)"
                            class="w-fit flex items-center gap-x-2 px-3 py-2 hover:text-primary hover:font-medium cursor-pointer text-[32px]"
                            @click="isMenuOpened = false">
                            {{ menu.label }} <ArrowUpRightIcon class="size-4" />
                        </InertiaLink>
                        <a
                            v-else
                            :href="menu.href"
                            class="px-3 py-2 hover:text-primary hover:font-medium cursor-pointer text-[32px]"
                            @click="isMenuOpened = false">
                            {{ menu.label }}
                        </a>
                    </template>

                    <a
                        v-else
                        :href="menu.href"
                        target="_blank"
                        class="w-fit flex items-center gap-x-2 px-3 py-2 hover:text-primary hover:font-medium cursor-pointer text-[32px]"
                        @click="isMenuOpened = false">
                        <span>{{ menu.label }} </span><ArrowUpRightIcon class="size-4" />
                    </a>
                </template>
                <div class="flex flex-col py-4 gap-y-4 w-full">
                    <div class="flex gap-4">
                        <InertiaLink
                            :href="route('path-selector')"
                            class="w-fit flex justify-center items-center gap-x-2 text-white font-semibold uppercase rounded-full px-6 py-[14px] bg-base-content max-h-[53px]">
                            <span>{{ $t('Join us') }} </span><ArrowUpRightIcon class="size-4" />
                        </InertiaLink>
                        <InertiaLink
                            :href="route('login')"
                            class="w-fit flex justify-center items-center gap-x-2 text-base-content font-medium rounded-full border border-base-content px-6 py-4 uppercase max-h-[53px]">
                            <span>{{ $t('Login') }} </span><ArrowUpRightIcon class="size-4" />
                        </InertiaLink>
                    </div>

                    <div class="flex flex-col gap-4 mt-6 text-sm">
                        <span class="uppercase text-base-300">{{ $t('Industries we help') }}</span>
                        <div class="flex flex-wrap gap-2">
                            <span
                                v-for="industry in industriesWeHelp"
                                :key="industry"
                                class="px-4 py-1 bg-sp-base-100 rounded-full">
                                {{ industry }}
                            </span>
                        </div>
                    </div>
                    <slot />
                    <div class="flex flex-auto gap-x-4 mt-11">
                        <a
                            href="https://www.facebook.com/scalingplatform"
                            target="_blank"
                            class="flex items-center hover:text-primary hover:font-medium">
                            <Facebook class="size-6" /> Facebook
                        </a>
                        <!-- <a
                          href="https://www.instagram.com/jagaadgram"
                           
                            target="_blank"
                            class="flex items-center hover:text-primary hover:font-medium">
                            <Instagram class="size-6" /> Instagram
                </a> -->
                        <a
                            href="https://www.linkedin.com/company/scaling-platform/"
                            target="_blank"
                            class="flex items-center hover:text-primary hover:font-medium">
                            <Linkedin class="size-6" /> Linkedin
                        </a>
                    </div>
                </div>
            </div>
        </section>
    </section>
</template>
<script setup lang="ts">
import {computed, ref} from 'vue';
import {route} from 'ziggy-js';
import {Link as InertiaLink} from '@inertiajs/vue3';
import {Facebook, Linkedin} from '@/Components/icons';
import ApplicationLogoBlack from '@/images/app_logo_black.svg';
import {industriesWeHelp} from '@/utils/static-data';
import {ArrowUpRightIcon, Bars2Icon, XMarkIcon} from '@heroicons/vue/24/solid';

const isMenuOpened = ref(false);

const props = defineProps<{
    layout: 'client' | 'vendor';
    primaryMenuLinks: {href: string; label: string; button?: boolean; hasBackground?: boolean}[];
    sidebarMenuLinks: {href: string; label: string; external?: boolean; isRoute?: boolean}[];
}>();

const primaryLinks = computed(() => props.primaryMenuLinks.filter((link) => !link.button));

const primaryButtons = computed(() => props.primaryMenuLinks.filter((link) => link.button === true));
</script>
